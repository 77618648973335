<template>
  <div class="scoreSelectionBox">
    <div class="informationBox">
      <div class="Tit">{{ examData.examName }}</div>
      <div class="li">
        <span>考点：{{ examData.sysOrgExamPlaceName }}</span>
        <span>考场：{{ examData.sysOrgExamPlaceRoomName }}</span>
      </div>
      <div class="li">
        <span>科目：{{ examData.subjectType | subjectTypeFilters }}</span>
        <span v-if="examData.arrangeModel == 1"
          >考试时间：{{ examData.examStartTime | dayList }} -
          {{ examData.examEndTime | dayList }}</span
        >
        <span v-if="examData.arrangeModel == 2"
          >考试批次：{{ examData.examSession }}</span
        >
      </div>
    </div>
    <div style="color: #0e0e0e; padding-left: 20px">
      最多可评阅的考生数：{{ examData.sceneMarkNum }}
    </div>
    <div style="position: relative">
      <div
        style="position: absolute; top: 0; width: 100%; pointer-events: none"
      >
        <div
          class="selectionBox selectionBox1"
          v-for="(item, i) in seatData.seatCodeArrange"
          :key="i"
        >
          <!-- ?('background:#AAAAAA;color:#fff;width:'+selectionBoxSpanWidth) -->
          <span
            v-for="val in item"
            :key="val"
            :style="
              val == 999
                ? 'visibility: hidden;width:' + selectionBoxSpan
                : seatData.selectedSeatArrange.indexOf(val) !== -1
                ? 'background:#AAAAAA;color:#fff;width:' + selectionBoxSpan
                : seatData.currentSelectSeatArrange.indexOf(val) !== -1
                ? 'background:#13CE66;color:#fff;width:' + selectionBoxSpan
                : 'width:' + selectionBoxSpan
            "
            >{{ val }}</span
          >
        </div>
      </div>
      <div
        class="selectionBox"
        v-for="(item, i) in seatData.seatArrange"
        :key="i"
      >
        <!-- ?('background:#AAAAAA;color:#fff;width:'+selectionBoxSpanWidth) -->
        <span
          v-for="val in item"
          :key="val"
          :style="
            val == 999
              ? 'visibility: hidden;width:' + selectionBoxSpan
              : seatData.selectedSeatArrange.indexOf(val) !== -1
              ? 'background:#AAAAAA;color:#fff;width:' + selectionBoxSpan
              : seatData.currentSelectSeatArrange.indexOf(val) !== -1
              ? 'background:#13CE66;color:#fff;width:' + selectionBoxSpan
              : 'width:' + selectionBoxSpan
          "
          @click="checkedSpan(val)"
        >
          <i style="opacity: 0;">{{ val }}</i>
        </span>
      </div>
    </div>

    <el-button
      v-throttle
      type="primary"
      :loading="loadingBtn"
      class="enterBtn"
      @click="enterBtn"
      :disabled="!seatData.currentSelectSeatArrange.length"
      >进入（已选{{ seatData.currentSelectSeatArrange.length }}/{{
        examData.sceneMarkNum
      }}）</el-button
    >
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  sceneExamInfo,
  sceneSeat,
  saveSeat,
  getLeaderTeacher,
} from '@/api/PadRating/PadRating.js'
export default {
  name: 'scoreSelection',
  data() {
    return {
      sendData: {
        examId: '',
        RoomId: '',
        PlaceId: '',
        examTimeId: '',
      },
      examData: {
        examEndTime: 0, //当前场次考试结束时间
        examId: 0, //考试id
        examName: '', //	考试名称
        examPlaceId: 0, //考试考场id
        examSession: 0, //考试考场当前场次
        examSessionCount: 0, //考试考场总场次
        examSessionStatus: 0, //当前场次状态
        examStartTime: 0, //当前场次考试开始时间
        examStatus: 0, //考试状态
        examTimeId: 0, //考试时间id
        sceneMarkNum: 0, //现场评分每位教师评阅学生数量
        seatCount: 0, //考场座位数
        subjectType: 0, //学科类型
        sysOrgExamPlaceId: 0, //考场id
        sysOrgExamPlaceName: '', //考场名称
        sysOrgExamPlaceRoomId: 0, //考点id
        sysOrgExamPlaceRoomName: '', //考点名称
      },
      seatData: {
        currentSelectSeatArrange: [], //当前用户选择的座位
        seatArrange: [], //座位排布 2维数组
        seatCount: 0, //座位数
        selectedSeatArrange: [999], //已被其他用户选择的座位
        cancelSeatArrange: [], //取消考试考生
        finishSeatArrange: [], //完成评分
        notExistSeatArrange: [], //不存在考生
      },
      loadingBtn: false,
      selectionBoxSpan: 'calc(25% - 20px)',
      teacherLeader: false,
    }
  },
  created() {
    this.sendData.examId = Number(this.$route.query.examId)
    this.sendData.RoomId = Number(this.$route.query.sysOrgExamPlaceRoomId)
    this.sendData.PlaceId = Number(this.$route.query.sysOrgExamPlaceId)
    this.examInformation(
      this.sendData.examId,
      this.sendData.RoomId,
      this.sendData.PlaceId
    )
  },
  mounted() {},
  methods: {
    // 获取考试信息
    examInformation(examId, RoomId, PlaceId) {
      sceneExamInfo(examId, RoomId, PlaceId).then((res) => {
        console.log('获取考试信息', res)
        if (res.success) {
          this.sendData.examTimeId = res.data.examTimeId
          this.examData = res.data
          if (!!res.data.examTimeId) {
            this.getLeaderTeacherFunc(this.sendData.examId)
            // this.seatInformation(
            //   this.sendData.examTimeId,
            //   this.sendData.examId,
            //   this.sendData.RoomId
            // )
          } else {
            if (res.data.arrangeModel == 2) {
              this.$message.warning('请先建立考试批次')
            } else {
              this.$message.warning('考试已结束')
            }
            setTimeout(() => {
              this.$router.back()
            }, 500)
          }
        } else {
          this.$message.warning(res.msg + '正在返回上一层页面')
          setTimeout(() => {
            this.$router.back()
          }, 500)
        }
      })
    },
    // 获取考点教室座位信息
    seatInformation(examTimeId, examId, RoomId) {
      sceneSeat(examTimeId, examId, RoomId).then((res) => {
        console.log('获取考试信息', res)
        if (res.success) {
          this.seatData = res.data
          if (
            !!this.seatData.seatArrange.length &&
            !!this.seatData.seatArrange[0].length
          ) {
            this.selectionBoxSpan = `calc( ${
              100 / this.seatData.seatArrange[0].length
            }% - 20px)`
          }
          if (!this.teacherLeader) {
            this.seatData.selectedSeatArrange.push(
              ...this.seatData.finishSeatArrange
            )
          } else {
            this.seatData.selectedSeatArrange = []
          }
          this.seatData.selectedSeatArrange.push(
            ...this.seatData.cancelSeatArrange,
            ...this.seatData.notExistSeatArrange
          )
        }
      })
    },
    // 点击位置
    checkedSpan(val) {
      if (this.seatData.selectedSeatArrange.indexOf(val) !== -1) return //this.$message.warning('已被其他用户选择的座位');
      if (this.seatData.currentSelectSeatArrange.indexOf(val) !== -1) {
        this.seatData.currentSelectSeatArrange.splice(
          this.seatData.currentSelectSeatArrange.indexOf(val),
          1
        )
      } else if (
        this.seatData.currentSelectSeatArrange.length >=
        this.examData.sceneMarkNum
      ) {
        return this.$message.warning(
          '最多可评阅的考生' + this.examData.sceneMarkNum + '名'
        )
      } else {
        this.seatData.currentSelectSeatArrange.push(val)
      }
    },
    // 提交选择的座位
    enterBtn() {
      console.log(this.seatData)
      this.loadingBtn = true
      let data = {
        examId: this.examData.examId,
        examMarkTeacherSceneSeatChosenId: null,
        examPlaceId: this.examData.examPlaceId,
        examTimeId: this.examData.examTimeId,
        seatArrange: [...this.seatData.currentSelectSeatArrange],
        sysOrgExamPlaceId: this.examData.sysOrgExamPlaceId,
        sysOrgExamPlaceRoomId: this.examData.sysOrgExamPlaceRoomId,
      }
      // 排序
      data.seatArrange.sort()
      console.log('座位信息', data.seatArrange)
      saveSeat(data)
        .then((res) => {
          this.loadingBtn = false

          console.log('交选择的座位', res)
          if (res.success) {
            // 转跳页面
            this.$router.push({
              path: '/siteScoring',
              query: {
                examTimeId: this.examData.examTimeId,
                examId: this.examData.examId,
                examPlaceId: this.examData.examPlaceId,
              },
            })
          } else {
            this.loadingBtn = false
            this.$message.warning(res.msg + ' 正在刷新页面')
            setTimeout(() => {
              window.location.reload()
            }, 700)
          }
        })
        .catch((err) => {
          this.$message.warning('提交失败')
          this.loadingBtn = false
        })
    },
    //【评分】 查询是否为阅卷组长
    getLeaderTeacherFunc(examId) {
      getLeaderTeacher(examId)
        .then((res) => {
          if (res.success) {
            this.teacherLeader = res.data
          }
          this.seatInformation(
            this.sendData.examTimeId,
            this.sendData.examId,
            this.sendData.RoomId
          )
        })
        .catch((err) => {})
    },
  },
  filters: {
    dayList(val) {
      // return dayjs(val - 28800000).format('HH:mm:ss');
      if (val) {
        return dayjs(val).format('HH:mm:ss')
      }
      return ''
    },
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 960px) {
}
@media screen and (max-width: 960px) {
}
.scoreSelectionBox {
  padding: 0 20px;
}
.informationBox {
  margin: 10px 0;
}
.informationBox .Tit {
  line-height: 30px;
  padding: 10px 0;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}
.informationBox .li {
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  padding: 6px 0;
}
.informationBox .li span {
  display: inline-block;
  margin: 0 18px;
}
.selectionBox {
  margin: 0 auto;
  padding: 10px 0;
  /* display: flex; */
  width: 100%;
  box-sizing: border-box;
}
.selectionBox span {
  cursor: pointer;
  width: calc(25% - 20px);
  height: 68px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  margin: 0 10px 20px 10px;
}
.selectionBox1 span {
  border: 0;
}
.enterBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
</style>
