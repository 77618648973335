var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scoreSelectionBox"},[_c('div',{staticClass:"informationBox"},[_c('div',{staticClass:"Tit"},[_vm._v(_vm._s(_vm.examData.examName))]),_c('div',{staticClass:"li"},[_c('span',[_vm._v("考点："+_vm._s(_vm.examData.sysOrgExamPlaceName))]),_c('span',[_vm._v("考场："+_vm._s(_vm.examData.sysOrgExamPlaceRoomName))])]),_c('div',{staticClass:"li"},[_c('span',[_vm._v("科目："+_vm._s(_vm._f("subjectTypeFilters")(_vm.examData.subjectType)))]),(_vm.examData.arrangeModel == 1)?_c('span',[_vm._v("考试时间："+_vm._s(_vm._f("dayList")(_vm.examData.examStartTime))+" - "+_vm._s(_vm._f("dayList")(_vm.examData.examEndTime)))]):_vm._e(),(_vm.examData.arrangeModel == 2)?_c('span',[_vm._v("考试批次："+_vm._s(_vm.examData.examSession))]):_vm._e()])]),_c('div',{staticStyle:{"color":"#0e0e0e","padding-left":"20px"}},[_vm._v(" 最多可评阅的考生数："+_vm._s(_vm.examData.sceneMarkNum)+" ")]),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","top":"0","width":"100%","pointer-events":"none"}},_vm._l((_vm.seatData.seatCodeArrange),function(item,i){return _c('div',{key:i,staticClass:"selectionBox selectionBox1"},_vm._l((item),function(val){return _c('span',{key:val,style:(val == 999
              ? 'visibility: hidden;width:' + _vm.selectionBoxSpan
              : _vm.seatData.selectedSeatArrange.indexOf(val) !== -1
              ? 'background:#AAAAAA;color:#fff;width:' + _vm.selectionBoxSpan
              : _vm.seatData.currentSelectSeatArrange.indexOf(val) !== -1
              ? 'background:#13CE66;color:#fff;width:' + _vm.selectionBoxSpan
              : 'width:' + _vm.selectionBoxSpan)},[_vm._v(_vm._s(val))])}),0)}),0),_vm._l((_vm.seatData.seatArrange),function(item,i){return _c('div',{key:i,staticClass:"selectionBox"},_vm._l((item),function(val){return _c('span',{key:val,style:(val == 999
            ? 'visibility: hidden;width:' + _vm.selectionBoxSpan
            : _vm.seatData.selectedSeatArrange.indexOf(val) !== -1
            ? 'background:#AAAAAA;color:#fff;width:' + _vm.selectionBoxSpan
            : _vm.seatData.currentSelectSeatArrange.indexOf(val) !== -1
            ? 'background:#13CE66;color:#fff;width:' + _vm.selectionBoxSpan
            : 'width:' + _vm.selectionBoxSpan),on:{"click":function($event){return _vm.checkedSpan(val)}}},[_c('i',{staticStyle:{"opacity":"0"}},[_vm._v(_vm._s(val))])])}),0)})],2),_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],staticClass:"enterBtn",attrs:{"type":"primary","loading":_vm.loadingBtn,"disabled":!_vm.seatData.currentSelectSeatArrange.length},on:{"click":_vm.enterBtn}},[_vm._v("进入（已选"+_vm._s(_vm.seatData.currentSelectSeatArrange.length)+"/"+_vm._s(_vm.examData.sceneMarkNum)+"）")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }